
<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" size="mini" style="float:left;margin:15px 15px 0px 0px;" icon="el-icon-plus" @click.native="isAddDialog = true;editType = ''">新增套餐</el-button>

        <el-input style="width:150px;float:left;margin:15px 15px 0px 0px;" size="mini" placeholder="请输入套餐标题搜索" v-model="search"></el-input>

        <el-button style="float:left;margin:15px 15px 0px 0px;" type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
      </el-col>
      <el-col>
        <el-table stripe :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
          <el-table-column type="index" align="center" label="序号" width="55"></el-table-column>
          <el-table-column prop="packageName" label="套餐标题" align="center" width="350">
            <template slot-scope="scope">
              <div class="goto-detail" @click="editDetail(scope.row.pid, 1)">{{scope.row.packageName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="packageNumber" align="center" label="套餐编码" width="130"></el-table-column>
          <el-table-column prop="packagePrice" align="center" label="套餐售价" width="130"></el-table-column>
          <el-table-column prop="amountPrice" align="center" label="金额价格" width="130"></el-table-column>
          <el-table-column prop="integralPrice" align="center" label="积分价格" width="130"></el-table-column>
          <el-table-column prop="createTime" align="center" label="生成时间" width="160"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" style="color:#333" icon="el-icon-view" @click="editDetail(scope.row.pid,1)">查看</el-button>
              <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.pid,2)">编辑</el-button>
              <el-button type="text" icon="el-icon-remove-outline" @click="setDisable(scope.row)">{{!scope.row.isEnable ? '禁用' : '启用'}}</el-button>
              <el-button type="text" style="color:red" icon="el-icon-delete" @click="delect(scope.row.pid)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog :close-on-click-modal="false" :title="!editType ? '新增套餐':editType == 1 ? '套餐详情':'编辑套餐'" :visible.sync="isAddDialog" v-if="isAddDialog" :lock-scroll="true" width="690px">
      <Add v-if="isAddDialog" :isAddDialog.sync="isAddDialog" :editId.sync="editId" :editType.sync="editType"></Add>
    </el-dialog>
  </div>

</template>
<script>
import { getPackageList, delPackage, addOrEditorVouchersPackage } from '@/api/setting';
import Add from './components/add.vue'

export default {
  name: 'outlets',
  components: {
    Add,
  },
  data() {
    return {
      isAddDialog: false,
      isDisable: '',           //状态
      editId: '',
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加


      search: '', //搜索

      list: [
      ],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  watch: {
    isAddDialog(n, o) {
      //关闭dialog后获取列表
      !n && this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    delect(id) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        delPackage(id)
          .then((response) => {
            if (response.code == 200) {
              this.list = this.list.filter(item => item.pid !== id);
            }
            this.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    getList() {
      let param = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        search: this.search
      };
      getPackageList(param)
        .then((response) => {
          if (response.code == 200) {
            this.total = response.data.total;
            this.list = response.data.records;

          } else {
            this.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    clearSearch() {
      this.search = '';
      this.isDisable = '';
      this.organizationArea = '';
    },
    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isAddDialog = true;
    },

    setDisable(item) {
      let isEnable = item.isEnable
      this.$confirm(isEnable == 1 ? '是否启用该卡券?' : '是否禁用该卡券?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          pid: item.pid,
          isEnable: isEnable ? 0 : 1
        }
        addOrEditorVouchersPackage(param).then((response) => {
          if (response.code == 200) {
            this.getList();
          }
          this.$message({
            type: response.code == 200 ? 'success' : 'default',
            duration: 2000,
            message: response.msg || response.error_msg,
          });
        })
      }).catch(err => {

      });
    },
  },
}
</script>