<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="125px">
    <el-row>
      <el-col :span="11">
        <el-form-item label="套餐名称" prop="packageName">
          <el-input style="width:100%;" placeholder="中英文、字母或数字组合,长度小于20位" v-model="form.packageName" maxlength="20" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.packageName}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="套餐名编码">
          <el-input style="width:100%;" placeholder="中英文、字母或数字组合,长度小于20位" v-model="form.packageNumber" maxlength="20" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.packageNumber}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="套餐售价" prop="packagePrice">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model.number="form.packagePrice" maxlength="11" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.packagePrice}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="金额价格" prop="amountPrice" :rules="{ required: true, message: '金额价格不可为空', trigger: 'blur' }">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model.number="form.amountPrice" maxlength="11" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.amountPrice}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="积分价格" prop="integralPrice" :rules="{ required: true, message: '积分价格不可为空', trigger: 'blur' }">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model.number="form.integralPrice" maxlength="11" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.integralPrice}}</div>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="21">
        <el-form-item label="总面值">
          <div class="content-text">{{packageFaceValue}}</div>
        </el-form-item>
      </el-col> -->

      <el-col :span="24">
        <el-form-item label="卡券区域" style="margin:0px;">
          <el-button size="mini" icon="el-icon-plus" type="primary" @click="addItems" v-if="editShow && itemsList.length < vouchersList.length">添加</el-button>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-for="(items,index) in itemsList" :key="index" style="padding:5px 0px;">
        <el-col :span="12">
          <el-select v-model="items.vouchersId" placeholder="请选择卡券" style="width:255px;float:right;" :disabled="!editShow" size="samll">
            <el-option v-for="(item,idx) in vouchersList" :key="idx" :label="`${item.vouchersTitle} (${item.regionName||''} ${item.supplierType==1?'鹤大师':item.supplierType==0?'车多':''})`" :value="item.vouchersId"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input-number style="float:left;margin:0px 10px;" v-model="items.vouchersNumber" :min="0" :disabled="!editShow" size="samll"></el-input-number>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-button type="danger" size="samll" plain icon="el-icon-delete" @click="delItems(index)" :disabled="!editShow">删除</el-button>
        </el-col>
      </el-col>

      <el-col :span="22">
        <el-form-item label="规则内容">
          <div id="div1" style="width:100%;height:330px;" v-if="editShow"></div>
          <div style="border:1px solid #eee;padding:10px;border-radius:10px;" class="content-text" v-else v-html="form.ruleContent"></div>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-top:30px;">
        <el-button type="primary" v-debounce="onSubmit" v-if="editShow">立即保存</el-button>
        <el-button @click="dialogClose">关闭窗口</el-button>
      </el-col>

    </el-row>
  </el-form>
</template>
<script>
import { baseURL } from '@/utils/http';
import { getVouchersList, addOrEditorVouchersPackage, getPackageInfo } from '@/api/setting';

import uploadFileOnly from '@/components/uploadFileOnly'
import { getCookies } from '@/utils/utils'

import E from 'wangeditor'
var editor = null
export default {
  props: ['isAddDialog', 'editId', 'editType'],
  data() {
    var checkMoney = (rule, value, callback) => {
      let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确金额'));
      }

      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入正确金额'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      itemsList: [
        {
          vouchersId: '',
          vouchersNumber: 0,
          amountPrice: '',
          integralPrice: '',
        }
      ],
      vouchersList: [],
      form: {
        packagePrice: '',                 //套餐价格
        packageName: '',                //套餐标题
        packageNumber: '',
        amountPrice: '',
        integralPrice: '',
        ruleContent: ''
      },
      rules: {
        packagePrice: [
          { required: true, message: '销售金额不可为空', trigger: 'blur' },
          { validator: checkMoney, trigger: 'blur' }
        ],
        packageName: [
          { required: true, message: '卡券标题不可为空', trigger: 'blur' },
        ],
      },

    }
  },
  computed: {
    editShow() {
      return this.editType == 2 || !this.editType
    },
    // packageFaceValue() {
    //   let totalAdd = 0
    //   this.itemsList.filter(item => item.vouchersNumber > 0).forEach((item) => {
    //     console.log(item)
    //     totalAdd = totalAdd + (Number(item.vouchersNumber) * Number(item.vouchersPrice))
    //   })
    //   return totalAdd
    // }
  },
  created() {
    this.getVouchersList();
    this.editType && this.editId && this.getDateil();
  },
  mounted() {
    if (this.editShow) {
      editor = new E('#div1');
      editor.config.height = 250;
      editor.config.uploadImgServer = baseURL + '/uploadFile/uploadFileTwo';
      editor.config.uploadFileName = 'mfile'

      let headers = {
        'x-token': getCookies('x-token')
      }
      editor.config.uploadImgHeaders = headers;

      editor.config.uploadImgMaxLength = 1
      editor.create();
      console.log(this.form.ruleContent)
    }
  },
  methods: {
    delItems(index) {
      this.itemsList.splice(index, 1)
    },
    addItems() {
      this.itemsList.push({
        vouchersId: '',
        vouchersNumber: 0,
      })
    },
    getVouchersList() {
      let param = {
        // isPage: 0,
        // pageNum: 1,
        // pageSize: 100,
        // vouchersType: '0',
        // usedArea: '440000'
      };
      getVouchersList(param)
        .then((response) => {
          if (response.code == 200) {
            this.vouchersList = response.data;
          } else {
            this.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    getDateil() {
      getPackageInfo(this.editId).then((response) => {
        if (response.code == 200) {
          this.form = response.data;
          this.itemsList = response.data['infoList']
          if (this.editShow) {
            editor.txt.html(this.form.ruleContent)
          }
        } else {
          this.$message.error({
            title: '错误',
            message: response.msg,
            duration: 1500
          });
        }
      })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit(formName = 'form') {
      let form = JSON.parse(JSON.stringify(this.form));
      form.infoList = this.itemsList.filter((item) => item.vouchersNumber > 0 && item.vouchersId)
      form.ruleContent = editor.txt.html();
      if (form.infoList.length == 0) {
        this.$message.error({
          title: '错误',
          message: '请完善套餐卡券内容',
          duration: 1500
        });
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addOrEditorVouchersPackage(form)
            .then((response) => {
              if (response.code == 200) {
                this.dialogClose();
                this.$message({
                  message: response.msg,
                  type: 'success'
                });
              } else {
                this.$message.error({
                  title: '错误',
                  message: response.msg,
                  duration: 1500
                });
              }
            })
        } else {
          this.$message.error({
            title: '错误',
            message: '请检查完善输入项并重试',
            duration: 1500
          });
          return false;
        }
      })
    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>